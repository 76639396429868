import React, { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './component/loader';

// All Headers
const Header1 = React.lazy(() => import('./layouts/header/Header1'));

// All Footers

const FooterStyle02 = React.lazy(
  () => import('./layouts/footer/footer-style-02')
);

const BackTop = React.lazy(() => import('./component/back-top'));

// Home
const HomeIndex = React.lazy(() => import('./pages/home1/index'));
const Blog = React.lazy(() => import('./pages/blog/Blog'));
const BlogGrid = React.lazy(() => import('./pages/blog/blog-grid'));
const BlogSingle = React.lazy(() => import('./pages/blog/blog-details'));
const CaseStudy = React.lazy(() => import('./pages/case-study/case-study'));

const ContactUs = React.lazy(() => import('./pages/contact/contact'));
const Faq = React.lazy(() => import('./pages/faq/faq'));
const ComingSoon = React.lazy(() => import('./pages/coming-soon'));
const Error404 = React.lazy(() => import('./pages/error404/error404'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacy-policy'));
const TermsConditions = React.lazy(() => import('./pages/terms-conditions'));

// Pages
const AboutUs = React.lazy(() => import('./pages/about-us'));
const TeamSingle = React.lazy(() => import('./pages/team/team-single'));
const MissionVision = React.lazy(() => import('./pages/mission-vision'));
const OurValue = React.lazy(() => import('./pages/our-value'));
const Service = React.lazy(() => import('./pages/services/services'));
const ServicesSingle = React.lazy(
  () => import('./pages/services/services-details')
);

function App() {
  const location = useLocation();
  const hideFooter = location.pathname !== '/coming-soon';

  return (
    <Suspense fallback={<Loader />}>
      <div className="main-wrapper">
        <Routes>
          <Route path="/" element={<Header1 />}>
            {/* Home */}
            <Route index path="/" element={<HomeIndex />} />

            {/* Pages */}
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-grid" element={<BlogGrid />} />
            <Route path="/blog-details/:id" element={<BlogSingle />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/*" element={<Error404 />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />

            {/* Company */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/team-details/:id" element={<TeamSingle />} />

            <Route path="/mission-vision" element={<MissionVision />} />
            <Route path="/our-value" element={<OurValue />} />
            <Route path="/service" element={<Service />} />
            <Route path="/service-details/:id" element={<ServicesSingle />} />

            {/* Case Study */}
            <Route path="/case-study" element={<CaseStudy />} />
          </Route>
          {/* Coming Soon Page */}
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/careers" element={<ComingSoon />} />
          //
        </Routes>

        {/* All Footer */}
        {hideFooter && (
          <Routes>
            <Route path="/*" element={<FooterStyle02 />} />{' '}
          </Routes>
        )}

        {/* Back to Top */}
        <BackTop />
      </div>
    </Suspense>
  );
}

export default App;
